import React, { useEffect, useState } from 'react';
import Footer from "../components/Footer";
import Ribbon from "../components/Ribbon";
import Nav from "../components/Navigation";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/main.css';


const Layout = ({ name, props, args, mode, children }) => {
    return (
        <div data-name={name} data-mode={mode}>
            <Ribbon />
            <Nav />
            <main className="content-wrapper">
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;



