import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import LMSLogo from "../assets/images/logo.svg";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Navigation() {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const [parentStyle, setParentStyle] = useState(false);
  const showParentStyle = (e) => {
    setParentStyle(true);
  };
  const hideParentStyle = (e) => {
    setParentStyle(false);
  };

  return (
    <>
      <div className="nav-container">
        <Navbar
          className="navbar"
          expand="lg"
          style={{ justifyContent: "space-between" }}
        >
          <Navbar.Brand className="lms-logo-nav">
            <Link to="/">
              <img className="nav-logo" src={LMSLogo} alt="LMS logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto text-center">
              <NavDropdown.Divider />
              <Link className="nav-items nav-link" to="/" activeClassName="active-page">
                HOME
              </Link>
              <NavDropdown.Divider />
              <NavDropdown
                className={parentStyle ? "active-page nav-items" : null}
                title="ABOUT"
                id="basic-nav-dropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
                style={{ border: "none" }}
              >
                <div className="dropdown-wrapper">
                  <NavDropdown.Item
                    id="leadership-team"
                    className="text-center nav-drop"
                    onMouseEnter={showParentStyle}
                    onMouseLeave={hideParentStyle}
                  >
                    <Link to="/team">Leadership Team</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    id="culture"
                    className="text-center nav-drop"
                    href="/company-culture"
                    onMouseEnter={showParentStyle}
                    onMouseLeave={hideParentStyle}
                  >
                    <Link to="/company-culture">Our Culture</Link>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <NavDropdown.Divider />
              <Link
                className="nav-items nav-link"
                to="/careers"
                activeClassName="active-page"
              >
                CAREERS
              </Link>
              <NavDropdown.Divider />
              <Link
                className="nav-items nav-link"
                to="/contact"
                activeClassName="active-page"
              >
                CONTACT
              </Link>
              <NavDropdown.Divider />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
}

export default Navigation;
